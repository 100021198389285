import { useAccountTransactionsQuery } from 'modules/bank/queries/useAccountTransactions.query'
import { Account, Provider } from 'modules/bank/types'
import { RoundedElevatedCard } from 'modules/common/components/RoundedElevatedCard/RoundedElevatedCard'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { useMemo, useState } from 'react'
import { AccountSelectionDropdown } from './AccountSelectionDropdown/AccountSelectionDropdown'
import { useDropdownMenuAccounts } from './AccountSelectionDropdown/useDropdownMenuAccounts'
import { AccountTransactionsExportButton } from './AccountTransactionsExportButton/AccountTransactionsExportButton'
import { TransactionListContainer } from './TransactionListContainer'
import { TransactionListTooltip } from './TransactionListTooltip/TransactionListTooltip'

interface Props {
  accounts: Account[]
  providers: Provider[]
}

export const TransactionListCard: React.FC<Props> = ({
  accounts,
  providers,
}) => {
  const { t } = useTranslation('bank-components')
  const dropdownMenuAccounts = useDropdownMenuAccounts({
    accounts,
    providers,
  })

  const [selectedAccountId, setSelectedAccountId] = useState(
    dropdownMenuAccounts[0].id
  )
  const selectedAccount = useMemo(
    () => accounts.find(({ id }) => selectedAccountId === id),
    [accounts, selectedAccountId]
  )

  const isBlockedInvest = selectedAccount?.isBlockedInvest

  const {
    data: transactionList,
    isLoading: isTransactionsLoading,
    isSuccess: isTransactionsSuccess,
    isError: isTransactionsError,
    refetch: refetchTransactions,
  } = useAccountTransactionsQuery(selectedAccountId, {
    enabled: !isBlockedInvest,
  })

  return (
    <RoundedElevatedCard>
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <h3 className="font-bold">{t('transactionListCard.title')}</h3>
          <TransactionListTooltip />
        </div>
        <div className="flex items-center gap-4">
          {selectedAccount && (
            <AccountTransactionsExportButton
              account={selectedAccount}
              disabled={
                !isTransactionsSuccess ||
                transactionList?.length === 0 ||
                isBlockedInvest
              }
            />
          )}
          <AccountSelectionDropdown
            dropdownMenuAccounts={dropdownMenuAccounts}
            selectedAccountId={selectedAccountId}
            onAccountSelected={setSelectedAccountId}
          />
        </div>
      </div>

      <TransactionListContainer
        refetchTransactions={refetchTransactions}
        selectedAccountId={selectedAccountId}
        isTransactionsError={isTransactionsError}
        isTransactionsLoading={isTransactionsLoading}
        isTransactionsSuccess={isTransactionsSuccess}
        isBlockedInvest={isBlockedInvest}
        transactionList={transactionList}
      />
    </RoundedElevatedCard>
  )
}
