import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import Axios from 'axios'
import { PaginatedList } from 'modules/common/types/utils'
import { UseQueryOptions } from 'react-query'
import { Provider } from '../types/accounts'
import { EnumBankQueryKeys } from './keys'

interface FetchProvidersParams {
  filter: 'ManagementToolsOnboarding' | null
}

const fetchProviders = async (
  accessToken: string,
  params?: FetchProvidersParams
) => {
  const apiUrl = `${process.env.NEXT_PUBLIC_API_URL}/v1/bank/accounts/providers`

  const { data } = await Axios.get<PaginatedList<Provider>>(apiUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params,
  })
  return data.data
}

interface Props<TData> {
  queryOptions?: UseQueryOptions<Provider[], Error, TData>
  fetchParams?: FetchProvidersParams
}

export const useProvidersQuery = <TData = Provider[]>({
  queryOptions,
  fetchParams,
}: Props<TData> = {}) => {
  const queryKey = [
    EnumBankQueryKeys.PROVIDERS_LIST,
    ...(fetchParams ? [fetchParams] : []),
  ]

  return useQueryWithAuth(
    queryKey,
    async (_context, accessToken) => fetchProviders(accessToken, fetchParams),
    queryOptions
  )
}
