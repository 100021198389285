import { useTranslation } from 'modules/common/hooks/useTranslation'
import { useCallback } from 'react'
import {
  ContentModalProps,
  useContentModal,
} from '../useContentModal/useContentModal'
import { TypeformModalContent } from './TypeformModalContent'

export interface TypeformModalProps extends Omit<ContentModalProps, 'content'> {
  isError?: boolean
  isLoading?: boolean
  hiddenFields?: Record<string, string>
  formId: string
  onTypeformSubmitted: () => Promise<unknown> | unknown
  errorRefetch: () => void
}

export const useTypeformModal = ({
  isLoading = false,
  isError,
  hiddenFields,
  formId,
  onTypeformSubmitted: onTypeformSubmittedFromProps,
  errorRefetch,
  ...props
}: TypeformModalProps) => {
  const { i18n } = useTranslation('common-components')

  const onTypeformSubmitted = useCallback(async () => {
    try {
      await onTypeformSubmittedFromProps()
      typeformModal.hideModal()
    } catch (error) {
      typeformModal.hideModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onTypeformSubmittedFromProps])

  const typeformModal = useContentModal({
    content: (
      <TypeformModalContent
        errorRefetch={errorRefetch}
        formId={formId}
        hiddenFields={hiddenFields}
        onTypeformSubmitted={onTypeformSubmitted}
        isError={isError}
        isLoading={isLoading}
      />
    ),
    i18n,
    classNames: { modalBody: 'w-modal-content-xl h-full', modalRoot: 'h-full' },
    ...props,
  })

  return typeformModal
}
