import { PropsWithChildren } from 'react'

export interface CircleProgressBarProps {
  progressPercentage: number
  progressColor?: string
  circleBgColor?: string
  size?: string
  strokeWidth?: string
  roundedStroke?: boolean
  className?: string
}

const radius = 175
const diameter = Math.round(Math.PI * radius * 2)
const getOffset = (val = 0) =>
  Math.round(((100 - Math.min(val, 100)) / 100) * diameter)

export const CircleProgressBar: React.FC<
  PropsWithChildren<CircleProgressBarProps>
> = ({
  children,
  progressPercentage = 0,
  progressColor = 'text-primary',
  circleBgColor = 'text-primary-90',
  size = '100',
  strokeWidth = '25',
  roundedStroke = true,
  className,
}) => {
  const strokeDashoffset = getOffset(progressPercentage)
  const strokeLinecap = roundedStroke ? 'round' : 'butt'

  return (
    <svg
      width={size}
      height={size}
      viewBox="-25 -25 400 400"
      className={className}
    >
      <circle
        stroke="currentColor"
        className={circleBgColor}
        cx="175"
        cy="175"
        r="175"
        strokeWidth={strokeWidth}
        fill="none"
      />
      <circle
        className={progressColor}
        stroke="currentColor"
        transform="rotate(-90 175 175)"
        cx="175"
        cy="175"
        r="175"
        strokeDasharray="1100"
        strokeWidth={strokeWidth}
        strokeDashoffset="1100"
        strokeLinecap={strokeLinecap}
        fill="none"
        style={{ strokeDashoffset }}
      />
      {children}
    </svg>
  )
}
