import { Transaction } from 'modules/bank/types'

export enum TDAEEligibilityState {
  Waiting = 'Waiting',
  Eligible = 'Eligible',
  NotEligible = 'NotEligible',
}

export enum TDAEPeriodicity {
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Undefined = 'Undefined',
}

export enum TDAEStatus {
  Accepted = 'Accepted',
  Refused = 'Refused',
  Undefined = 'Undefined',
}

export enum TDAERecordStatus {
  NotOpen = 'NotOpen',
  NotDeclared = 'NotDeclared',
  Declared = 'Declared',
  Paid = 'Paid',
}

export enum TDAEState {
  InProgress = 'InProgress',
  Archived = 'Archived',
}

export interface CorporateTDAEInformation {
  id: string
  corporateId: string
  eligibility: TDAEEligibilityState
  status: TDAEStatus
  periodicity: TDAEPeriodicity
}

export enum UncategorizedTransactionsStatus {
  Pending = 'Pending',
  Done = 'Done',
  Error = 'Error',
}
export interface UncategorizedAccountTransactions {
  jobId: string
  status: UncategorizedTransactionsStatus
  data: Transaction[]
}

export interface TDAERecord {
  corporateId: string
  sortKey: string
  corporateTDAEInformationId: string
  declarationNumber: string
  status: TDAERecordStatus
  totalAmount?: number
  totalTurnover: number
  turnover: {
    blank: TDAETurnoverWithTotal
    externalAccounts?: TDAETurnoverWithTotalAndAccountId[]
    others: TDAETurnoverWithTotal
  }
  periodicity: TDAEPeriodicity
  state: TDAEState
  year: number
  trimester: number
  month: number
  startDate: string
  endDate: string
}

export type PatchTDAERecordPayload = Partial<
  Omit<TDAERecord, 'turnover'> & {
    turnover: {
      blank: TDAETurnover
      externalAccounts?: TDAETurnoverWithAccountId[]
      others: TDAETurnover
    }
  }
>

export interface TDAETurnover {
  turnoverSale: {
    ht: number
    ttc: number
  }
  turnoverServices: {
    ht: number
    ttc: number
  }
  turnoverOtherServices: {
    ht: number
    ttc: number
  }
  turnoverCipav: {
    ht: number
    ttc: number
  }
  turnoverRental: {
    ht: number
    ttc: number
  }
}

export const TURNOVER_TYPES: (keyof TDAETurnover)[] = [
  'turnoverSale',
  'turnoverRental',
  'turnoverServices',
  'turnoverOtherServices',
  'turnoverCipav',
]

export interface TDAETurnoverWithTotal extends TDAETurnover {
  turnoverTotal: {
    ht: number
    ttc: number
  }
}

export interface TDAETurnoverWithAccountId extends TDAETurnover {
  accountId: string
}

export type TDAETurnoverWithTotalAndAccountId = TDAETurnoverWithTotal &
  TDAETurnoverWithAccountId

export enum EnumTDAEDeclarationTabs {
  IN_PROGRESS = 'IN_PROGRESS',
  ARCHIVED = 'ARCHIVED',
}

export interface PostEstimateTDAEChargeAmountPayload {
  sortKey: string
  turnoverDetail: TDAETurnover
}

export interface PostTDAEDeclarationValidationPayload {
  sortKey: string
}

export interface TDAEMandate {
  accountOwnerName: string
  bankName: string
  iban: string
  rum: string
}

export interface PostTDAERecordPaymentPayload {
  rum: string
  sortKey: string
}

export type PostTDAERecordPaymentResponse = Pick<
  TDAERecord,
  | 'corporateId'
  | 'sortKey'
  | 'corporateTDAEInformationId'
  | 'declarationNumber'
  | 'status'
  | 'totalAmount'
  | 'turnover'
>
