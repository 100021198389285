import { RoundedElevatedCard } from 'modules/common/components/RoundedElevatedCard/RoundedElevatedCard'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { Document } from 'modules/invoicing/types/document'
import { NewDocumentButton } from '../NewDocumentButton/NewDocumentButton'
import { DocumentList } from './DocumentList/DocumentList'

interface Props {
  className?: string
  documents: Document[]
}

export const DocumentListCard: React.FC<Props> = ({ className, documents }) => {
  const { t } = useTranslation('invoicing-components')

  return (
    <RoundedElevatedCard className={className}>
      <div className="flex items-center justify-between gap-2">
        <h3 className="font-bold">{t('documentListCard.title')}</h3>
        <NewDocumentButton variant="link" className="p-0" />
      </div>
      <DocumentList documentList={documents} />
    </RoundedElevatedCard>
  )
}
