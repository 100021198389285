import { useTranslation } from 'modules/common/hooks/useTranslation'
import Image from 'next/image'
import React from 'react'

export const IncreaseTransferLimitsRequestSuccessModalContent = () => {
  const { t } = useTranslation('bank-components')

  return (
    <div className="flex w-modal-content flex-col space-y-4">
      <div>
        <Image src="/assets/img/check.png" width="100" height="100" />
      </div>
      <p className="text-2xl font-bold">
        {t('increaseTransferLimitsRequestSuccessModal.title')}
      </p>
      <p>{t('increaseTransferLimitsRequestSuccessModal.text')}</p>
    </div>
  )
}
