import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import Axios from 'axios'
import { UseQueryOptions } from 'react-query'
import { CardLimit, FetchAccountCardLimitsResponse } from '../types/card'
import { EnumBankQueryKeys } from './keys'

interface FetchAccountCardLimitsArgs {
  accountId: string
  cardId: string
  accessToken: string
}

const fetchAccountCardLimits = async ({
  accountId,
  accessToken,
  cardId,
}: FetchAccountCardLimitsArgs) => {
  const { data } = await Axios.get<FetchAccountCardLimitsResponse>(
    `${process.env.NEXT_PUBLIC_API_URL}/v1/bank/accounts/${accountId}/cards/${cardId}/limits`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
  return data?.data
}

interface Props {
  accountId?: string
  cardId?: string
  queryOptions?: UseQueryOptions<CardLimit[], Error>
}
export const useAccountCardLimitsQuery = ({
  accountId,
  cardId,
  queryOptions,
}: Props) => {
  return useQueryWithAuth<CardLimit[], Error>(
    EnumBankQueryKeys.ACCOUNT_CARD_LIMITS,
    async (context, accessToken) => {
      if (!accountId) {
        throw new Error(
          'useAccountCardLimitsQuery: Missing parameter accountId'
        )
      }

      if (!cardId) {
        throw new Error('useAccountCardLimitsQuery: Missing parameter cardId')
      }

      return fetchAccountCardLimits({
        accessToken,
        accountId,
        cardId,
      })
    },
    {
      ...queryOptions,
      enabled: !!accountId && !!cardId && queryOptions?.enabled,
    }
  )
}
