import { ErrorReloadSmall } from 'modules/common/components/ErrorReloadSmall/ErrorReloadSmall'
import { RoundedElevatedCard } from 'modules/common/components/RoundedElevatedCard/RoundedElevatedCard'
import { useTranslation } from 'modules/common/hooks/useTranslation'

interface Props {
  title: string
  titleIcon?: JSX.Element
  onReload: () => void
}

export const InternalAccountCardError: React.FC<Props> = ({
  title,
  titleIcon,
  onReload,
}) => {
  const { t } = useTranslation('home-components')

  return (
    <RoundedElevatedCard>
      <h3 className="flex gap-x-2 pb-4 font-bold">
        {titleIcon}
        {title}
      </h3>
      <ErrorReloadSmall
        description={t('internalAccountCardError.description')}
        withIcon={false}
        withTitle={false}
        onReload={onReload}
      />
    </RoundedElevatedCard>
  )
}
