import clsx from 'clsx'
import { isString } from 'lodash'
import { Trans } from 'next-i18next'
import {
  IconCrossRounded,
  IconDanger,
  IconInformationSquare,
} from '../../_icons/icons'

type MessageType = 'info' | 'important' | 'warning' | 'error'

export interface MessageProps {
  className?: string
  title?: string
  content: string | React.ReactNode
  type?: MessageType
  withIcon?: boolean
}

const MESSAGE_ICONS: {
  [key in MessageType]: JSX.Element
} = {
  info: <IconInformationSquare />,
  important: <IconInformationSquare />,
  warning: <IconDanger />,
  error: <IconCrossRounded />,
}

export const Message: React.FC<MessageProps> = ({
  className,
  title,
  content,
  type = 'info',
  withIcon = false,
}) => {
  return (
    <div
      className={clsx(
        'flex rounded-lg p-2 text-sm',
        {
          'bg-info-90 text-info': type === 'info',
          'bg-primary-90 text-primary': type === 'important',
          'bg-warning-90 text-warning': type === 'warning',
          'bg-danger-90 text-danger': type === 'error',
        },
        className
      )}
      data-testid={`${type}-message`}
    >
      {withIcon && <span className="mr-2">{MESSAGE_ICONS[type]}</span>}
      <div className="flex flex-col">
        {title && (
          <Trans parent="p" className="font-bold">
            {title}
          </Trans>
        )}
        {isString(content) ? <Trans parent="p">{content}</Trans> : content}
      </div>
    </div>
  )
}
