import { Button } from 'modules/common/components/_ui/Button/Button'
import { Trans } from 'next-i18next'
import { EnumRoutes } from 'modules/common/routes'
import Image from 'next/image'
import {
  CorporateLifecycle,
  EnumLifecycleNextAction,
} from 'modules/corporate/types/lifecycle'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { Corporate } from 'modules/corporate/types/corporates'
import { getIsKycStarted } from 'modules/auth/lib/utils/getIsKycStarted'
import { AccountOpeningCardProgressBar } from './AccountOpeningCardProgressBar/AccountOpeningCardProgressBar'

export interface AccountOpeningCardProps {
  lifecycle: CorporateLifecycle
  corporate: Corporate
}

export const AccountOpeningCard = ({
  lifecycle,
  corporate,
}: AccountOpeningCardProps) => {
  const { t } = useTranslation('home-components')

  const isKycStarted = getIsKycStarted({ corporate })
  const isOnboardingRefused =
    lifecycle.nextAction === EnumLifecycleNextAction.ONBOARDING_REFUSED

  const context = isKycStarted ? lifecycle.nextAction?.toUpperCase() : null

  return (
    <div className="relative flex justify-between overflow-hidden rounded-2xl border border-info-200 bg-white shadow-md">
      <div className="absolute h-full w-[6px] bg-primary" />
      <div className="flex flex-col gap-6 p-10">
        <h2 className="text-2xl font-bold">
          {t('accountOpeningCard.title', { context })}
        </h2>
        <Trans parent="p" className="whitespace-pre-line font-headings">
          {t('accountOpeningCard.description', { context })}
        </Trans>

        <AccountOpeningCardProgressBar
          lifecycle={lifecycle}
          corporate={corporate}
        />

        <Button
          href={isKycStarted ? EnumRoutes.KYC_ROOT : EnumRoutes.OFFER_SELECTION}
          variant={isOnboardingRefused ? 'outline' : 'contained'}
          className="max-w-fit"
          color="active"
        >
          {t('accountOpeningCard.ctaLabel', { context })}
        </Button>
      </div>
      <div className="relative w-[450px] flex-shrink-0 self-stretch">
        <Image
          src="/assets/img/home-page-both-cards.png"
          layout="fill"
          objectFit="cover"
        />
      </div>
    </div>
  )
}
