import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import { TooltipProps } from 'modules/common/hooks/useTooltip'
import { forwardRef } from 'react'
import { useTheme } from './Tooltip.theme'

export type TooltipColor = 'primary' | 'danger' | 'warning' | 'success'

interface Props extends TooltipProps {
  color: TooltipColor
}
export const Tooltip = forwardRef<HTMLDivElement, Props>(
  ({ children, style, isOpen, renderLayer, className, color }, ref) => {
    const { themeClass } = useTheme({
      color,
    })

    return renderLayer(
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={clsx(
              'max-w-max rounded p-4 drop-shadow-lg filter',
              themeClass,
              className
            )}
            ref={ref}
            style={{ ...style, minWidth: 200 }}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.1 }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    )
  }
)
