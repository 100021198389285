import { useExportExternalAccountTransactionsModal } from 'modules/bank/modals/transaction/useExportExternalAccountTransactionsModal'
import { useExportInternalAccountTransactionsModal } from 'modules/bank/modals/transaction/useExportInternalAccountTransactionsModal'
import { Account, EnumAccountSource } from 'modules/bank/types'
import { IconDownloadArrow } from 'modules/common/components/_icons/icons'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { useCallback } from 'react'

interface Props {
  account: Account
  disabled?: boolean
}

export const AccountTransactionsExportButton: React.FC<Props> = ({
  account,
  disabled,
}) => {
  const {
    showModal: showExportInternalAccountTransactionsModal,
  } = useExportInternalAccountTransactionsModal(account)

  const {
    showModal: showExportExternalAccountTransactionsModal,
  } = useExportExternalAccountTransactionsModal(account)

  const showExportAccountTransactionsModal = useCallback(
    () =>
      account.source === EnumAccountSource.INTERNAL
        ? showExportInternalAccountTransactionsModal()
        : showExportExternalAccountTransactionsModal(),
    [
      account.source,
      showExportExternalAccountTransactionsModal,
      showExportInternalAccountTransactionsModal,
    ]
  )

  return (
    <Button
      color="active"
      variant="none"
      icon={<IconDownloadArrow />}
      onClick={showExportAccountTransactionsModal}
      disabled={disabled}
    />
  )
}
