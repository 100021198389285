import { useCallback } from 'react'
import { useFeedbackAlertContextDispatch } from '../contexts/FeedbackAlertContext'

export interface FeedbackAlertCallbacksProps {
  errorTitle?: string
  errorDescription?: string
  successTitle?: string
  successDescription?: string
  successDisabled?: boolean
  errorDisabled?: boolean
}
export const useFeedbackAlertCallbacks = ({
  errorTitle,
  errorDescription,
  successTitle,
  successDescription,
  successDisabled,
  errorDisabled,
}: FeedbackAlertCallbacksProps) => {
  const { showAlert } = useFeedbackAlertContextDispatch()

  const dispatchErrorFeedbackAlert = useCallback(async () => {
    if (!errorDescription || errorDisabled) {
      return
    }

    return showAlert({
      title: errorTitle,
      description: errorDescription,
      type: 'error',
    })
  }, [errorDescription, errorDisabled, errorTitle, showAlert])

  const dispatchSuccessFeedbackAlert = useCallback(async () => {
    if (!successDescription || successDisabled) {
      return
    }

    return showAlert({
      title: successTitle,
      description: successDescription,
      type: 'success',
    })
  }, [showAlert, successDescription, successDisabled, successTitle])

  return {
    onSuccess: dispatchSuccessFeedbackAlert,
    onError: dispatchErrorFeedbackAlert,
  }
}
