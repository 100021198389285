import {
  ErrorReloadSmall,
  ErrorReloadSmallProps,
} from 'modules/common/components/ErrorReloadSmall/ErrorReloadSmall'
import { RoundedElevatedCard } from 'modules/common/components/RoundedElevatedCard/RoundedElevatedCard'

export const TransactionListCardError: React.FC<ErrorReloadSmallProps> = (
  props
) => (
  <RoundedElevatedCard>
    <ErrorReloadSmall {...props} withIcon={false} className="px-6 py-20" />
  </RoundedElevatedCard>
)
