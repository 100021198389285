import {
  BankingLimitsCard,
  BankingLimitsCardType,
} from 'modules/bank/components/account/BankingLimitsCard/BankingLimitsCard'
import { BankingLimitsCardSkeleton } from 'modules/bank/components/account/BankingLimitsCard/BankingLimitsCardSkeleton'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { InternalAccountCardError } from '../InternalAccountCardError/InternalAccountCardError'
import { useInternalAccountTransferLimitsQueries } from './useInternalAccountTransferLimitsQueries'

export const InternalAccountTransferLimitsCardContainer = () => {
  const { t } = useTranslation('bank-components')

  const {
    data: { monthlyLimit, dailyLimit },
    isError,
    isLoading,
    refetch,
  } = useInternalAccountTransferLimitsQueries()

  const cardType = BankingLimitsCardType.TRANSFER

  if (isError) {
    return (
      <InternalAccountCardError
        title={t('bankingLimitsCard.title', { context: cardType })}
        onReload={refetch}
      />
    )
  }

  if (isLoading || !(monthlyLimit && dailyLimit)) {
    return <BankingLimitsCardSkeleton />
  }

  return (
    <BankingLimitsCard
      type={cardType}
      dailyLimit={dailyLimit}
      monthlyLimit={monthlyLimit}
    />
  )
}
