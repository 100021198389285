import { EnumOfferType } from 'modules/billing/types/offers'
import { RoundedElevatedCard } from 'modules/common/components/RoundedElevatedCard/RoundedElevatedCard'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { EnumRoutes } from 'modules/common/routes'
import { CorporateOffer } from 'modules/corporate/types/offers'
import Image from 'next/image'

interface Props {
  offer: CorporateOffer
}

export const BankCardActivationCard: React.FC<Props> = ({ offer }) => {
  const { t } = useTranslation('home-components')

  return (
    <RoundedElevatedCard className="relative">
      <h3 className="mb-2 font-bold">{t('bankCardActivationCard.title')}</h3>
      <div className="h-full">
        <div className="flex h-full flex-col">
          <p className="w-2/3 text-info-400">
            {t('bankCardActivationCard.description')}
          </p>
          <Button
            href={EnumRoutes.ACCOUNT_CARD}
            variant="contained"
            className="mt-auto w-fit"
            color="active"
          >
            {t('bankCardActivationCard.ctaLabel')}
          </Button>
        </div>
      </div>
      <div className="absolute right-0 bottom-0 h-[170px] w-[157px]">
        {offer.offerType === EnumOfferType.EXTENDED_CARD && (
          <Image src="/assets/img/home-extended-card.png" layout="fill" />
        )}
        {offer.offerType === EnumOfferType.BASIC_CARD && (
          <Image src="/assets/img/home-basic-card.png" layout="fill" />
        )}
      </div>
    </RoundedElevatedCard>
  )
}
