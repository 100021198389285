import { useInternalAccount } from 'modules/bank/hooks/account/useInternalAccount'
import { useAccountCardLimitsQuery } from 'modules/bank/queries/useAccountCardLimits.query'
import { Card, CardLimitResourceId } from 'modules/bank/types'
import { useCallback } from 'react'

export const useInternalCardLimitsQueries = ({ resourceId: cardId }: Card) => {
  const {
    data: internalAccount,
    isLoading: isInternalAccountLoading,
    isError: isInternalAccountError,
    isSuccess: isInternalAccountSuccess,
    errorRefetch: refetchInternalAccount,
  } = useInternalAccount()

  const {
    data: cardLimits,
    isLoading: isCardLimitsLoading,
    isError: isCardLimitsError,
    isSuccess: isCardLimitsSuccess,
    refetch: refetchCardLimits,
  } = useAccountCardLimitsQuery({
    accountId: internalAccount?.id,
    cardId,
  })

  const refetch = useCallback(() => {
    if (isInternalAccountError) {
      refetchInternalAccount()
    }
    if (isCardLimitsError) {
      refetchCardLimits()
    }
  }, [
    isInternalAccountError,
    refetchInternalAccount,
    isCardLimitsError,
    refetchCardLimits,
  ])

  return {
    refetch,
    isLoading: isInternalAccountLoading || isCardLimitsLoading,
    isError: isInternalAccountError || isCardLimitsError,
    isSuccess: isInternalAccountSuccess && isCardLimitsSuccess,
    data: {
      dailyLimit: cardLimits?.find(
        ({ resourceId }) =>
          resourceId === CardLimitResourceId.LIMIT_GLOBAL_DAILY
      )?.parametersRule,
      monthlyLimit: cardLimits?.find(
        ({ resourceId }) => resourceId === CardLimitResourceId.LIMIT_GLOBAL
      )?.parametersRule,
    },
  }
}
