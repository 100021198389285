import { IconInternalAccount } from 'modules/common/components/_icons/IconInternalAccount/IconInternalAccount'
import { Skeleton } from 'modules/common/components/_ui/Skeletons/Skeleton/Skeleton'

export const AccountBadge = ({ uri }: { uri?: string }) => {
  return (
    <div className="h-11 w-11 shrink-0 rounded-full border-2 border-primary-100 bg-white p-2 shadow-md">
      {uri ? <img src={uri} /> : <IconInternalAccount />}
    </div>
  )
}

export const AccountBadgeSkeleton = () => (
  <Skeleton className="h-11 w-11 shrink-0 rounded-full" />
)
