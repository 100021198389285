import clsx from 'clsx'
import { PropsWithChildren } from 'react'

export type RoundedElevatedCardBgColors = 'white' | 'primary'

interface Props extends PropsWithChildren {
  backgroundColor?: RoundedElevatedCardBgColors
  className?: string
}

export const RoundedElevatedCard: React.FC<Props> = ({
  backgroundColor = 'white',
  className,
  children,
}) => (
  <div
    className={clsx(
      'flex w-full flex-col rounded-2xl border p-6 shadow-md',
      backgroundColor === 'white' && 'border-info-100 bg-white',
      backgroundColor === 'primary' && 'border-primary-100 bg-primary-90',
      className
    )}
  >
    {children}
  </div>
)
