import dayjs from 'dayjs'
import { Transaction } from 'modules/bank/types'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { EnumRoutes } from 'modules/common/routes'
import { TransactionAmount } from '../../TransactionAmount/TransactionAmount'
import { TransactionIcon } from '../../TransactionIcon/TransactionIcon'

interface Props {
  transactionList: Transaction[]
  accountId: string
}

export const TransactionList: React.FC<Props> = ({
  transactionList,
  accountId,
}) => {
  const { t } = useTranslation(['bank-components', 'common'])

  if (transactionList.length === 0) {
    return (
      <div className="flex flex-col items-center space-y-6 py-24">
        <h3 className="font-default text-2xl font-bold">
          {t('transactionList.noTransactionTitle')}
        </h3>
        <p className="w-96 text-center text-info-400">
          {t('transactionList.noTransactionDescription')}
        </p>
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-full px-4 py-3">
        <table className="w-full">
          <tbody className="divide-y divide-info-100">
            {transactionList.slice(0, 5).map((transaction) => {
              const { id, counterpartyName, bookingDate } = transaction
              return (
                <tr key={id} className="w-full">
                  <td className="w-1/2 max-w-0 truncate py-3 font-bold">
                    <TransactionIcon
                      transaction={transaction}
                      className="mr-2 inline"
                    />
                    <span className="inline">{counterpartyName}</span>
                  </td>
                  <td className="w-1/4 min-w-[88px] py-3 font-bold text-info-400">
                    {dayjs(bookingDate).format('DD/MM/YYYY')}
                  </td>
                  <td className="w-1/4 min-w-[108px] py-3 text-right">
                    <TransactionAmount transaction={transaction} />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {transactionList.length > 5 && (
        <Button
          href={`${EnumRoutes.ACCOUNTS}/${accountId}`}
          variant="link"
          color="active"
        >
          {t('common:seeMore')}
        </Button>
      )}
    </div>
  )
}
