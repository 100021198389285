import dayjs from 'dayjs'
import { decimalPrice } from 'modules/invoicing/lib/format/price'
import { DocumentFormProps } from '../components/documents/creation/DocumentCreationFields/types'
import { DocumentServiceFormProps } from '../components/documents/creation/DocumentServiceForm/types'
import { DocumentValidationFormProps } from '../hooks/document/documentValidation/useDocumentValidationForm'
import {
  Document,
  DocumentItemPayload,
  DocumentRow,
  DOCUMENT_TYPE_SLUGS,
  EnumDocumentStatus,
  EnumDocumentType,
  PostDocumentPayload,
  PostDocumentValidationPayload,
} from '../types/document'
import { mapCustomerToSelectCustomerField } from './customer'
import { formatDocumentNumber } from './format/documentNumber'

export const prepareDocumentListTable = (
  documentList: Document[]
): DocumentRow[] => {
  return documentList?.map(prepareDocumentRow)
}

export const prepareDocumentRow = ({
  id,
  documentNumber,
  title,
  createdAt,
  dueDate,
  isLate,
  status,
  totalWithoutVAT,
  customer,
  type,
}: Document): DocumentRow => ({
  id,
  documentNumber: formatDocumentNumber(documentNumber),
  title,
  customerName: customer?.name,
  createdAt: dayjs(createdAt).format('DD/MM/YYYY'),
  dueDate: dayjs(dueDate).format('DD/MM/YYYY'),
  isLate,
  status,
  totalWithoutVAT: decimalPrice(totalWithoutVAT),
  type,
})

export const mapDocumentFormToDocument = (
  { customer, title, items, percentageDiscountRate }: DocumentFormProps,
  type: EnumDocumentType
): PostDocumentPayload => {
  return {
    type,
    title,
    customerId: customer?.value,
    discountRate: percentageDiscountRate ? percentageDiscountRate / 100 : 0,
    items: items?.map(({ unitPriceWithoutVAT, ...rest }) => ({
      unitPriceWithoutVAT: Math.round(unitPriceWithoutVAT * 100), // Convert euros in cents
      ...rest,
    })),
  }
}

export const mapDocumentToDocumentForm = ({
  customer,
  title,
  items,
  discountRate,
}: Document): DocumentFormProps => {
  return {
    title,
    customer: mapCustomerToSelectCustomerField(customer),
    percentageDiscountRate: discountRate ? discountRate * 100 : null,
    items: items?.map(
      ({
        currency,
        description,
        name,
        quantity,
        unit,
        unitPriceWithoutVAT,
        VATRate,
      }) => ({
        currency,
        description,
        name,
        quantity,
        unit,
        unitPriceWithoutVAT: unitPriceWithoutVAT / 100, // Convert cents in euros
        VATRate,
      })
    ),
  }
}

export const mapDocumentServiceFormToDocumentItem = ({
  percentageVATRate,
  ...rest
}: DocumentServiceFormProps): DocumentItemPayload => {
  return {
    VATRate: percentageVATRate ? percentageVATRate / 100 : 0,
    currency: 'EUR',
    ...rest,
  }
}

export const mapDocumentItemToDocumentServiceForm = ({
  currency: _, // The way to omit a property from spread
  VATRate,
  ...rest
}: DocumentItemPayload): DocumentServiceFormProps => {
  return {
    percentageVATRate: VATRate ? VATRate * 100 : 0,
    ...rest,
  }
}

const delayFieldNames: Record<
  EnumDocumentType,
  keyof Pick<
    PostDocumentValidationPayload,
    'estimateAcceptationDays' | 'invoicePaymentDays'
  >
> = {
  [EnumDocumentType.ESTIMATE]: 'estimateAcceptationDays',
  [EnumDocumentType.INVOICE]: 'invoicePaymentDays',
}

export const mapDocumentValidationFormToPayload = (
  formData: DocumentValidationFormProps,
  documentType: EnumDocumentType
): PostDocumentValidationPayload => {
  const delayFieldName = delayFieldNames[documentType]

  return {
    documentNumber: formData.documentNumber,
    validatedAt: dayjs().toISOString(),
    [delayFieldName]: formData.delay,
  }
}

export const getDocumentRedirectionPath = (document: Document) => {
  const { status, type, id } = document
  if (status === EnumDocumentStatus.DRAFT) {
    return `/facturation/creation/${DOCUMENT_TYPE_SLUGS[type]}?documentId=${id}`
  } else {
    return `/facturation/documents/${id}`
  }
}
