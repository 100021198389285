import { buildTimeConfig } from 'config'
import { useSplitAccountListBySource } from 'modules/bank/hooks/account/useSplitAccountListBySource'
import { Account, Provider } from 'modules/bank/types'
import { useMemo } from 'react'
import { DropdownMenuAccount } from './AccountSelectionDropdown'

interface Props {
  accounts: Account[]
  providers: Provider[]
}

export const useDropdownMenuAccounts = ({
  accounts,
  providers,
}: Props): DropdownMenuAccount[] => {
  const { internalAccount, externalAccounts } = useSplitAccountListBySource(
    accounts
  )

  const internalAccountItem = useMemo(
    () =>
      internalAccount
        ? {
            bankName: buildTimeConfig.internalBankName,
            accountName: internalAccount.name,
            id: internalAccount.id,
          }
        : null,
    [internalAccount]
  )

  const externalAccountItems = useMemo(
    () =>
      externalAccounts.map(({ providerId, name, id }) => {
        const provider = providers.find(
          (provider) => providerId === provider.id
        )
        return {
          bankName: provider?.name || '',
          accountName: name,
          id,
          externalAccountIconUrl: provider?.logoUrl,
        }
      }),
    [externalAccounts, providers]
  )

  return [
    ...(internalAccountItem ? [internalAccountItem] : []),
    ...externalAccountItems,
  ]
}
