import { Card, CardStatusPriority } from '../../types'
import { useInternalAccountCards } from './useInternalAccountCards'

export const useCurrentCard = () => {
  const {
    data: { cards, internalAccount },
    ...rest
  } = useInternalAccountCards()
  // Sort card by status priority ACT->BLO->PRE->CAN->Others
  const sortedCards = cards?.sort((a: Card, b: Card) => {
    if (CardStatusPriority[a.statusCode] && CardStatusPriority[b.statusCode]) {
      return CardStatusPriority[a.statusCode] - CardStatusPriority[b.statusCode]
    }
    return 1000
  })

  return {
    data: { currentCard: sortedCards?.[0], internalAccount },
    ...rest,
  }
}
