import clsx from 'clsx'
import { Skeleton } from 'modules/common/components/_ui/Skeletons/Skeleton/Skeleton'

interface Props {
  className?: string
}

export const BankingLimitSkeleton: React.FC<Props> = ({ className }) => (
  <div
    className={clsx('flex content-center items-center space-x-4', className)}
  >
    <Skeleton className="h-12 w-12 shrink-0 rounded-full" />
    <div className="space-y-2">
      <Skeleton className="h-6 w-32" />
      <Skeleton className="h-6 w-40" />
    </div>
  </div>
)
