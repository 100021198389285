import { Link } from 'modules/common/components/Link/Link'
import { BannerMessage } from 'modules/common/components/_ui/BannerMessage/BannerMessage'
import { useTrackEvent } from 'modules/common/hooks/useTrackEvent'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { EnumRoutes } from 'modules/common/routes'
import { HOME_TRACKING_EVENTS } from 'modules/home/lib/tracking/events'
import { EnumServiceSlugs } from 'modules/services/types/services'
import { Trans } from 'next-i18next'
import { useTDAEDiscoveryBanner } from './useTDAEDiscoveryBanner'

export const TDAEDiscoveryBannerMessage = () => {
  const { t } = useTranslation('home-components')

  const { hideBanner, isBannerToBeDisplayed } = useTDAEDiscoveryBanner()
  const trackEvent = useTrackEvent()

  if (isBannerToBeDisplayed) {
    return (
      <BannerMessage withIcon type="important" onClose={hideBanner}>
        <Trans
          components={{
            lnk: (
              <Link
                href={`${EnumRoutes.SERVICES}/${EnumServiceSlugs.TDAE_URSSAF}`}
                className="text-primary"
                onClick={() =>
                  trackEvent({
                    eventType: HOME_TRACKING_EVENTS.TDAE_INTEREST_HOMEPAGE,
                  })
                }
              />
            ),
          }}
        >
          {t('tdaeDiscoveryBannerMessage')}
        </Trans>
      </BannerMessage>
    )
  }
  return null
}
