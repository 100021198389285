import { LocalStorageKeys } from '_constants'
import localStorage from '../storage/localStorage'

export enum BannerKey {
  DOCUMENTS_CONFIGURATION = 'DOCUMENTS_CONFIGURATION',
  INTERNAL_ACCOUNT_OPENING = 'INTERNAL_ACCOUNT_OPENING',
}

type LocalStorageBannersToHide = BannerKey[]

export const getBannerCloseState = (banner: BannerKey): boolean => {
  const bannersToHide = localStorage.retrieve<LocalStorageBannersToHide>(
    LocalStorageKeys.BANNERS_TO_HIDE
  )

  return Boolean(bannersToHide?.includes(banner))
}

export const storeBannerCloseState = (banner: BannerKey) => {
  const bannersToHide =
    localStorage.retrieve<LocalStorageBannersToHide>(
      LocalStorageKeys.BANNERS_TO_HIDE
    ) || []

  if (!bannersToHide.includes(banner)) {
    bannersToHide.push(banner)

    localStorage.store(LocalStorageKeys.BANNERS_TO_HIDE, bannersToHide)
  }
}
