import { useAuthenticatedUserCorporateQuery } from 'modules/auth/queries/useAuthenticatedUserCorporate.query'
import { ContentModalProps } from 'modules/common/modals/useContentModal/useContentModal'
import { useTypeformModal } from 'modules/common/modals/useTypeformModal/useTypeformModal'
import { useIncreaseTransferLimitsRequestSuccessModal } from './useIncreaseTransferLimitsRequestSuccessModal'

const FORM_ID =
  process.env.NEXT_PUBLIC_INCREASE_TRANSFER_LIMITS_TYPEFORM_ID || ''

export const useIncreaseTransferLimitsRequestModal = (
  props?: Omit<ContentModalProps, 'content'>
) => {
  const {
    data: { corporate, user },
    isLoading,
    isError,
    errorRefetch,
  } = useAuthenticatedUserCorporateQuery()

  const {
    showModal: showSuccessModal,
  } = useIncreaseTransferLimitsRequestSuccessModal()

  return useTypeformModal({
    formId: FORM_ID,
    onTypeformSubmitted: () => {
      showSuccessModal()
    },
    hiddenFields: {
      email: user?.email || '',
      company_name: corporate?.information?.name || '',
      first_name: corporate?.requester?.firstName || '',
      last_name: corporate?.requester?.lastName || '',
      corporate_id: corporate?.id || '',
    },
    isLoading,
    isError,
    errorRefetch,
    ...props,
  })
}
