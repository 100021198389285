import {
  Fill,
  PROGRESS_BAR_MAX,
} from 'modules/common/components/_ui/ProgressBar'
import { Corporate } from 'modules/corporate/types/corporates'
import {
  CorporateLifecycle,
  EnumLifecycleNextAction,
} from 'modules/corporate/types/lifecycle'

const STEPS = [
  EnumLifecycleNextAction.COMPANY,
  EnumLifecycleNextAction.ACTIVITY,
  EnumLifecycleNextAction.IDENTITY,
  EnumLifecycleNextAction.CARD_ORDER,
]

interface Props {
  lifecycle: CorporateLifecycle
  corporate: Corporate
}
export const useAccountOpeningCardProgressBar = ({
  lifecycle,
  corporate,
}: Props) => {
  const isStepWithProgressBar =
    corporate.subscriptions &&
    corporate.subscriptions.length > 0 &&
    lifecycle.nextAction &&
    STEPS.includes(lifecycle.nextAction)

  const progressBarTotal = STEPS.length
  const progressBarCurrent =
    STEPS.findIndex((stepName) => stepName === lifecycle?.nextAction) + 1
  const progressBarFill = Math.ceil(
    (progressBarCurrent / progressBarTotal) * PROGRESS_BAR_MAX
  ) as Fill

  return {
    isStepWithProgressBar,
    progressBarFill,
    progressBarTotal,
    progressBarCurrent,
  }
}
