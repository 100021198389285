import { Transaction } from 'modules/bank/types'
import { ErrorReloadSmall } from 'modules/common/components/ErrorReloadSmall/ErrorReloadSmall'
import { AccountBlockedInvest } from '../AccountBlockedInvest/AccountBlockedInvest'
import { TransactionList } from './TransactionList/TransactionList'
import { TransactionListSkeleton } from './TransactionList/TransactionListSkeleton'

interface Props {
  isTransactionsLoading?: boolean
  isTransactionsSuccess?: boolean
  isTransactionsError?: boolean
  isBlockedInvest?: boolean
  transactionList?: Transaction[]
  selectedAccountId: string
  refetchTransactions: () => void
}
export const TransactionListContainer = ({
  isTransactionsLoading,
  selectedAccountId,
  isTransactionsError,
  isTransactionsSuccess,
  isBlockedInvest,
  transactionList,
  refetchTransactions,
}: Props) => {
  if (isBlockedInvest) {
    return <AccountBlockedInvest />
  }
  if (isTransactionsLoading) {
    return <TransactionListSkeleton />
  }
  if (isTransactionsError) {
    return (
      <ErrorReloadSmall
        onReload={refetchTransactions}
        withIcon={false}
        className="px-6 py-16"
      />
    )
  }
  if (isTransactionsSuccess && transactionList) {
    return (
      <TransactionList
        transactionList={transactionList}
        accountId={selectedAccountId}
      />
    )
  }

  return null
}
