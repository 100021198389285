import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import Axios from 'axios'
import { UseQueryOptions } from 'react-query'
import { Card, FetchAccountCardsResponse } from '../types/card'
import { EnumBankQueryKeys } from './keys'

interface FetchAccountCardsArgs {
  accountId: string
  accessToken: string
}
const fetchAccountCards = async ({
  accountId,
  accessToken,
}: FetchAccountCardsArgs) => {
  const apiUrl = `${process.env.NEXT_PUBLIC_API_URL}/v1/bank/accounts/${accountId}/cards`

  const { data } = await Axios.get<FetchAccountCardsResponse>(apiUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return data?.data
}

export const useAccountCardsQuery = (
  accountId?: string,
  queryOptions?: UseQueryOptions<Card[], Error>
) => {
  return useQueryWithAuth<Card[], Error>(
    [EnumBankQueryKeys.ACCOUNT_CARDS_LIST],
    async (context, accessToken) => {
      if (!accountId) {
        throw new Error('useAccountCardsQuery: Missing parameter accountId')
      }

      return fetchAccountCards({ accountId, accessToken })
    },
    {
      ...queryOptions,
      enabled: !!accountId,
    }
  )
}
