import { useBanner } from 'modules/common/hooks/useBanner'
import { BannerKey } from 'modules/common/lib/banner'
import { EnumLifecycleStep } from 'modules/corporate/types/lifecycle'
import { useTDAEDiscoveryBannerQueries } from './useTDAEDiscoveryBannerQueries'

export const useTDAEDiscoveryBanner = () => {
  const {
    data: {
      canAccessTdaeUrssafService,
      hasExternalAccount,
      hasInternalAccount,
      isTDAEServiceNeverSubscribed,
      lifecycleTDAEServiceStatus,
    },
    isSuccess,
  } = useTDAEDiscoveryBannerQueries()

  const shouldDisplayTDAEDiscoveryBannerMessage = Boolean(
    isSuccess &&
      canAccessTdaeUrssafService &&
      isTDAEServiceNeverSubscribed &&
      (hasInternalAccount ||
        (hasExternalAccount &&
          lifecycleTDAEServiceStatus === EnumLifecycleStep.TODO))
  )

  return useBanner({
    bannerKey: BannerKey.DOCUMENTS_CONFIGURATION,
    displayCondition: shouldDisplayTDAEDiscoveryBannerMessage,
  })
}
