import { getIsKycComplete } from 'modules/auth/lib/utils/getIsKycComplete'
import { useCorporateLifecycleQueries } from 'modules/corporate/hooks/useCorporateLifecycleQueries'
import { AccountOpeningCard } from '../AccountOpeningCard/AccountOpeningCard'
import { AccountContainerErrorReload } from './AccountContainerErrorReload'
import { AccountContainerSkeleton } from './AccountContainerSkeleton'
import { BankCardContainer } from './BankCardContainer/BankCardContainer'
import { InternalAccountCardContainer } from './InternalAccountCardContainer/InternalAccountCardContainer'
import { InternalAccountTransferLimitsCardContainer } from './InternalAccountTransferLimitsCardContainer/InternalAccountTransferLimitsCardContainer'

export const AccountContainer = () => {
  const {
    data: { lifecycle, corporate },
    errorRefetch,
    isError,
    isLoading,
  } = useCorporateLifecycleQueries()

  const isKycComplete = getIsKycComplete(lifecycle)

  if (isLoading) {
    return <AccountContainerSkeleton />
  }

  if (isError || !lifecycle || !corporate) {
    return <AccountContainerErrorReload onReload={errorRefetch} />
  }

  if (isKycComplete) {
    return (
      <div className="grid grid-cols-3 gap-x-6">
        <InternalAccountCardContainer />
        <InternalAccountTransferLimitsCardContainer />
        <BankCardContainer />
      </div>
    )
  }

  return <AccountOpeningCard lifecycle={lifecycle} corporate={corporate} />
}
