import { ProgressBar } from 'modules/common/components/_ui/ProgressBar'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { Corporate } from 'modules/corporate/types/corporates'
import { CorporateLifecycle } from 'modules/corporate/types/lifecycle'
import { useAccountOpeningCardProgressBar } from './hooks/useAccountOpeningCardProgressBar'

interface Props {
  lifecycle: CorporateLifecycle
  corporate: Corporate
}
export const AccountOpeningCardProgressBar = ({
  lifecycle,
  corporate,
}: Props) => {
  const { t } = useTranslation('home-components')

  const {
    isStepWithProgressBar,
    progressBarCurrent,
    progressBarFill,
    progressBarTotal,
  } = useAccountOpeningCardProgressBar({ corporate, lifecycle })

  if (!isStepWithProgressBar) {
    return null
  }

  return (
    <div className="flex flex-col gap-2">
      <ProgressBar fill={progressBarFill} color="success" />
      <p className="text-sm leading-6 text-info-400">
        {t('accountOpeningCard.progressBarLabel', {
          context: lifecycle.nextAction?.toUpperCase(),
          current: progressBarCurrent,
          total: progressBarTotal,
        })}
      </p>
    </div>
  )
}
