import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import Axios from 'axios'
import { UseQueryOptions } from 'react-query'
import { AccountLimit, FetchAccountLimitsResponse } from '../types/card'
import { EnumBankQueryKeys } from './keys'

interface FetchAccountLimitsArgs {
  accountId: string
  accessToken: string
}

const fetchAccountLimits = async ({
  accountId,
  accessToken,
}: FetchAccountLimitsArgs) => {
  const { data } = await Axios.get<FetchAccountLimitsResponse>(
    `${process.env.NEXT_PUBLIC_API_URL}/v1/bank/accounts/${accountId}/limits`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
  return data?.data
}

export const useAccountLimitsQuery = (
  accountId?: string,
  queryOptions?: UseQueryOptions<AccountLimit[], Error>
) => {
  return useQueryWithAuth<AccountLimit[], Error>(
    EnumBankQueryKeys.ACCOUNT_LIMITS,
    async (context, accessToken) => {
      if (!accountId) {
        throw new Error('useAccountLimitsQuery: Missing parameter accountId')
      }

      return fetchAccountLimits({
        accessToken,
        accountId,
      })
    },
    {
      ...queryOptions,
      enabled: !!accountId,
    }
  )
}
