import { friendlyFormatIBAN } from 'ibantools'
import { Account } from 'modules/bank/types'
import { IconPlus } from 'modules/common/components/_icons/icons'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { CopyText } from 'modules/common/components/CopyText/CopyText'
import { RoundedElevatedCard } from 'modules/common/components/RoundedElevatedCard/RoundedElevatedCard'
import { IconInternalAccount } from 'modules/common/components/_icons/IconInternalAccount/IconInternalAccount'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { EnumRoutes } from 'modules/common/routes'
import { BalanceAmount } from '../BalanceAmount/BalanceAmount'

export interface AccountInformationCardProps {
  account: Account
}

export const AccountInformationCard: React.FC<AccountInformationCardProps> = ({
  account: { iban, balance, isBlockedInvest },
}) => {
  const { t } = useTranslation('bank-components')

  return (
    <RoundedElevatedCard>
      <div className="flex space-x-2 pb-4">
        <IconInternalAccount className="shrink-0" />
        <div>
          <h3 className="pb-4 font-bold">
            {t('accountInformationCard.title')}
          </h3>
          <p className="text-info-400">
            {t('accountInformationCard.ibanLabel')}
          </p>
          <div className="flex space-x-3">
            <p className="font-bold text-info-400">
              {friendlyFormatIBAN(iban)}
            </p>
            <CopyText textToCopy={iban} />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between pb-3 pt-7">
        <Button
          variant="link"
          className="!p-0"
          color="active"
          icon={<IconPlus />}
          href={EnumRoutes.ACCOUNT_NEW_TRANSFER}
        >
          {t('accountInformationCard.newTransfer')}
        </Button>
        {isBlockedInvest ? (
          <p className="font-headings text-2xl font-bold">***** €</p>
        ) : (
          <BalanceAmount amount={parseFloat(balance)} />
        )}
      </div>
    </RoundedElevatedCard>
  )
}
