import { LimitParametersRule } from 'modules/bank/types'
import { useMemo } from 'react'

interface Props {
  limit: LimitParametersRule
  disabled?: boolean
}
export const usePaymentLimit = ({ limit, disabled }: Props) => {
  const values = useMemo(() => {
    const maxValue = +limit.value
    const currentValue = maxValue - +(limit.remaining || 0)
    const progressPercentage =
      maxValue === 0 ? 0 : (100 * currentValue) / maxValue

    return {
      maxValue,
      currentValue,
      progressPercentage,
    }
  }, [limit])

  const theme = useMemo(() => {
    if (disabled) {
      return {
        progressColor: 'text-info-300',
        circleBgColor: 'text-info-200',
      }
    }
    if (values.progressPercentage < 50) {
      return {
        progressColor: 'text-success',
        circleBgColor: 'text-success-200',
      }
    }
    if (values.progressPercentage >= 50 && values.progressPercentage < 66) {
      return {
        circleBgColor: 'text-warning-200',
        progressColor: 'text-warning',
      }
    }
    if (values.progressPercentage >= 66) {
      return {
        progressColor: 'text-danger',
        circleBgColor: 'text-danger-200',
      }
    }
    return {
      progressColor: 'text-primary',
      circleBgColor: 'text-primary-90',
    }
  }, [disabled, values.progressPercentage])

  return { theme, values }
}
