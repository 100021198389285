import {
  storeBannerCloseState,
  getBannerCloseState,
  BannerKey,
} from 'modules/common/lib/banner'
import { useCallback, useMemo, useReducer } from 'react'

interface Props {
  bannerKey: BannerKey
  displayCondition: boolean
}

export const useBanner = ({ bannerKey, displayCondition }: Props) => {
  const [hasBannerBeenClosed, closeBanner] = useReducer(
    () => true,
    getBannerCloseState(bannerKey)
  )

  const isBannerToBeDisplayed = useMemo(() => {
    return displayCondition && !hasBannerBeenClosed
  }, [displayCondition, hasBannerBeenClosed])

  const hideBanner = useCallback(() => {
    closeBanner()
    storeBannerCloseState(bannerKey)
  }, [bannerKey])

  return {
    isBannerToBeDisplayed,
    hideBanner,
  }
}
