import { useInternalAccount } from 'modules/bank/hooks/account/useInternalAccount'
import { useAccountLimitsQuery } from 'modules/bank/queries/useAccountLimitsQuery.queries'
import { AccountLimitResourceId } from 'modules/bank/types'
import { useCallback } from 'react'

export const useInternalAccountTransferLimitsQueries = () => {
  const {
    data: internalAccount,
    isLoading: isInternalAccountLoading,
    isError: isInternalAccountError,
    isSuccess: isInternalAccountSuccess,
    errorRefetch: refetchInternalAccount,
  } = useInternalAccount()

  const {
    data: accountLimits,
    isLoading: isAccountLimitsLoading,
    isError: isAccountLimitsError,
    isSuccess: isAccountLimitsSuccess,
    refetch: refetchAccountLimits,
  } = useAccountLimitsQuery(internalAccount?.id)

  const refetch = useCallback(() => {
    if (isInternalAccountError) {
      refetchInternalAccount()
    }
    if (isAccountLimitsError) {
      refetchAccountLimits()
    }
  }, [
    isInternalAccountError,
    isAccountLimitsError,
    refetchInternalAccount,
    refetchAccountLimits,
  ])

  return {
    data: {
      dailyLimit: accountLimits?.find(
        ({ resourceId }) => resourceId === AccountLimitResourceId.DAILY
      )?.parametersRule,
      monthlyLimit: accountLimits?.find(
        ({ resourceId }) => resourceId === AccountLimitResourceId.MONTHLY
      )?.parametersRule,
    },
    isLoading: isInternalAccountLoading || isAccountLimitsLoading,
    isError: isInternalAccountError || isAccountLimitsError,
    isSuccess: isInternalAccountSuccess && isAccountLimitsSuccess,
    refetch,
  }
}
