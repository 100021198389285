import { useAuthenticatedUserQuery } from 'modules/auth/queries/useAuthenticatedUser.query'
import { EnumOfferType } from 'modules/billing/types/offers'
import { Seo } from 'modules/common/components/Seo/Seo'
import { useSelectSidebarMenu } from 'modules/common/components/Sidebar/hooks/useSelectSidebarMenu'
import { IconConsultFaqs } from 'modules/common/components/_icons/icons'
import { LayoutDashboard } from 'modules/common/components/_layouts/LayoutDashboard'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { isDay } from 'modules/common/lib/helpers/date/isDay'
import { externalLinkProps } from 'modules/common/lib/helpers/externalLinkProps'
import { BlankPage } from 'modules/common/types/page'
import { SidebarMenu } from 'modules/common/types/sidebar'
import { useCorporateOfferQuery } from 'modules/corporate/queries/useCorporateOffer.query'
import { AccountContainer } from 'modules/home/components/AccountContainer/AccountContainer'
import { AccountingContainer } from 'modules/home/components/AccountingContainer/AccountingContainer'
import { InvoicingContainer } from 'modules/home/components/InvoicingContainer/InvoicingContainer'
import { TDAEDiscoveryBannerMessage } from 'modules/home/components/TDAEDiscoveryBannerMessage/TDAEDiscoveryBannerMessage'
import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { externalLinks } from '_constants'

const HomePage: BlankPage = () => {
  const { t } = useTranslation('common-page-home')

  useSelectSidebarMenu({ menuId: SidebarMenu.HOME })

  const { data: authenticatedUser } = useAuthenticatedUserQuery()
  const {
    data: corporateOffer,
    isSuccess: isCorporateOfferSuccess,
  } = useCorporateOfferQuery(authenticatedUser?.corporateId)
  const firstName = authenticatedUser?.firstname

  return (
    <div className="flex w-full flex-col">
      <Seo title={t('meta.title')} noindex />
      <TDAEDiscoveryBannerMessage />
      <div className="grid h-fit w-full grid-cols-2 gap-6 px-10 py-8">
        <div className="col-span-2 flex items-center justify-between">
          <div>
            <h2 className="text-2xl font-bold">
              {t(isDay() ? 'greetings_day' : 'greetings_night', {
                firstName,
              })}
            </h2>
            <p className="text-info-400">{t('introduction')}</p>
          </div>
          <Button
            variant="outline"
            color="active"
            href={externalLinks.faq}
            {...externalLinkProps}
            icon={<IconConsultFaqs />}
          >
            {t('faqButtonLabel')}
          </Button>
        </div>
        {isCorporateOfferSuccess &&
          corporateOffer?.offerType !==
            EnumOfferType.STRICTLY_MANAGEMENT_TOOLS && (
            <div className="col-span-2">
              <AccountContainer />
            </div>
          )}
        <AccountingContainer />
        <InvoicingContainer />
      </div>
    </div>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale as string, [
      'common',
      'common-page-home',
      'common-components',
      'bank-components',
      'home-components',
      'invoicing-components',
    ])),
  },
})

HomePage.getLayout = (page) => (
  <LayoutDashboard className="bg-info-90">{page}</LayoutDashboard>
)
HomePage.authenticated = true

export default HomePage
