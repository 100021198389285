import { useMemo } from 'react'
import { Account, EnumAccountSource } from '../../types'

export const useSplitAccountListBySource = (accounts: Account[] = []) => {
  const internalAccount = useMemo(
    () =>
      accounts.find((account) => account.source === EnumAccountSource.INTERNAL),
    [accounts]
  )

  const externalAccounts = useMemo(
    () =>
      accounts.filter(
        (account) => account.source === EnumAccountSource.EXTERNAL
      ),
    [accounts]
  )

  return { internalAccount, externalAccounts }
}
