import clsx from 'clsx'
import { PropsWithChildren } from 'react'

export interface BannerProps {
  type?: 'info' | 'important' | 'warning' | 'error' | 'success'
  className?: string
}

export const Banner: React.FC<PropsWithChildren<BannerProps>> = ({
  className,
  type = 'info',
  children,
}) => {
  return (
    <div
      className={clsx(
        'flex p-2 text-info-600 shadow-md',
        // We need to explicit all the potential classes to prevent purge
        // https://github.com/nuxt-community/tailwindcss-module/issues/55
        {
          'bg-info-90': type === 'info',
          'bg-primary-90': type === 'important',
          'bg-warning-200': type === 'warning',
          'bg-danger-200': type === 'error',
          'bg-success-200': type === 'success',
        },
        className
      )}
    >
      {children}
    </div>
  )
}
