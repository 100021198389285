import { useIncreaseTransferLimitsRequestModal } from 'modules/bank/modals/transfer/IncreaseTransferLimitsRequestModal/useIncreaseTransferLimitsRequestModal'
import { LimitParametersRule } from 'modules/bank/types'
import { RoundedElevatedCard } from 'modules/common/components/RoundedElevatedCard/RoundedElevatedCard'
import { IconPen } from 'modules/common/components/_icons/icons'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { EnumRoutes } from 'modules/common/routes'
import { BankingLimit } from './BankingLimit/BankingLimit'

export enum BankingLimitsCardType {
  TRANSFER = 'transfer',
  PAYMENT = 'payment',
}

export interface BankingLimitsCardProps {
  type: BankingLimitsCardType
  dailyLimit: LimitParametersRule
  monthlyLimit: LimitParametersRule
  className?: string
  isManageLimitsButtonDisabled?: boolean
}

export const BankingLimitsCard: React.FC<BankingLimitsCardProps> = ({
  type,
  dailyLimit,
  monthlyLimit,
  className,
  isManageLimitsButtonDisabled = false,
}) => {
  const { t } = useTranslation('bank-components')
  const {
    showModal: showIncreaseTransferLimitsRequestModal,
  } = useIncreaseTransferLimitsRequestModal()

  return (
    <RoundedElevatedCard className={className}>
      <div className="flex flex-wrap justify-between">
        <h3 className="font-bold">
          {t('bankingLimitsCard.title', { context: type })}
        </h3>
        <Button
          variant="link"
          className="!p-0"
          color="active"
          icon={<IconPen />}
          disabled={isManageLimitsButtonDisabled}
          {...(type === BankingLimitsCardType.PAYMENT
            ? {
                href: EnumRoutes.ACCOUNT_CARD,
              }
            : {
                onClick: showIncreaseTransferLimitsRequestModal,
                type: 'button',
              })}
        >
          {t('bankingLimitsCard.manageLimits')}
        </Button>
      </div>
      <div className="divide-y divide-info-100">
        <BankingLimit
          limit={monthlyLimit}
          frequency="monthly"
          className="py-4"
        />
        <BankingLimit limit={dailyLimit} frequency="daily" className="pt-4" />
      </div>
    </RoundedElevatedCard>
  )
}
