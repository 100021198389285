import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import { IconCross, IconLightBulb } from '../../_icons/icons'
import { Banner, BannerProps } from '../Banner/Banner'
import { Button } from '../Button/Button'

export interface BannerMessageProps extends BannerProps {
  withIcon?: boolean
  onClose?: () => void
}

export const BannerMessage: React.FC<PropsWithChildren<BannerMessageProps>> = ({
  type,
  withIcon = false,
  className,
  onClose,
  children,
}) => {
  return (
    <Banner
      type={type}
      className={clsx(
        'flex items-center justify-center space-x-2 p-3 text-info',
        className
      )}
    >
      {withIcon && <IconLightBulb className="shrink-0" />}
      <div>{children}</div>
      {onClose && (
        <Button
          onClick={onClose}
          variant="none"
          icon={<IconCross className="h-6 w-6" />}
        />
      )}
    </Banner>
  )
}
