import { useAccountsQuery } from 'modules/bank/queries/useAccounts.query'
import { useProvidersQuery } from 'modules/bank/queries/useProviders.query'
import { useCallback } from 'react'

export const useTransactionListCardQueries = () => {
  const {
    data: providers,
    isLoading: isProvidersLoading,
    isError: isProvidersError,
    isSuccess: isProvidersSuccess,
    refetch: refetchProviders,
  } = useProvidersQuery()

  const {
    data: accountsData,
    isLoading: isAccountsLoading,
    isError: isAccountsError,
    refetch: refetchAccounts,
    isSuccess: isAccountsSuccess,
  } = useAccountsQuery()

  const errorRefetch = useCallback(() => {
    if (isProvidersError) {
      refetchProviders()
    } else if (isAccountsError) {
      refetchAccounts()
    }
  }, [isProvidersError, isAccountsError, refetchProviders, refetchAccounts])

  return {
    errorRefetch,
    isLoading: isProvidersLoading || isAccountsLoading,
    isError: isProvidersError || isAccountsError,
    isSuccess: isProvidersSuccess && isAccountsSuccess,
    data: {
      providers,
      accounts: accountsData?.data,
    },
  }
}
