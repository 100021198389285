import { useCtaModal } from 'modules/common/modals/useCtaModal/useCtaModal'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { IncreaseTransferLimitsRequestSuccessModalContent } from './IncreaseTransferLimitsRequestSuccessModalContent'

export const useIncreaseTransferLimitsRequestSuccessModal = () => {
  const { t, i18n } = useTranslation(['bank-components', 'common'])

  return useCtaModal({
    i18n,
    content: <IncreaseTransferLimitsRequestSuccessModalContent />,
    overlayTransitionType: 'transition-none',
    primaryButtonText: t('common:noted'),
    headerTitle: t('increaseTransferLimitsRequestSuccessModal.headerTitle'),
    buttonsAlignment: 'stretch',
  })
}
