import { RoundedElevatedCard } from 'modules/common/components/RoundedElevatedCard/RoundedElevatedCard'
import { Skeleton } from 'modules/common/components/_ui/Skeletons/Skeleton/Skeleton'
import { BankingLimitSkeleton } from './BankingLimit/BankingLimitSkeleton'

export const BankingLimitsCardSkeleton = () => (
  <RoundedElevatedCard>
    <div className="flex justify-between">
      <Skeleton className="h-6 w-44" />
    </div>
    <div className="divide-y divide-info-100">
      <BankingLimitSkeleton className="py-4" />
      <BankingLimitSkeleton className="pt-4" />
    </div>
  </RoundedElevatedCard>
)
