import { AccountInformationCard } from 'modules/bank/components/account/AccountInformationCard/AccountInformationCard'
import { AccountInformationCardSkeleton } from 'modules/bank/components/account/AccountInformationCard/AccountInformationCardSkeleton'
import { useInternalAccount } from 'modules/bank/hooks/account/useInternalAccount'
import { IconInternalAccount } from 'modules/common/components/_icons/IconInternalAccount/IconInternalAccount'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { InternalAccountCardError } from '../InternalAccountCardError/InternalAccountCardError'

export const InternalAccountCardContainer = () => {
  const { t } = useTranslation('bank-components')

  const {
    data: internalAccount,
    isLoading,
    isError,
    errorRefetch,
  } = useInternalAccount()

  if (isError) {
    return (
      <InternalAccountCardError
        title={t('accountInformationCard.title')}
        titleIcon={<IconInternalAccount />}
        onReload={errorRefetch}
      />
    )
  }

  if (isLoading || !internalAccount) {
    return <AccountInformationCardSkeleton />
  }

  return <AccountInformationCard account={internalAccount} />
}
