import { useAuthenticatedUserCorporateQuery } from 'modules/auth/queries/useAuthenticatedUserCorporate.query'
import { useCallback } from 'react'
import { TDAEStatus } from '../../types/tdae'
import { useFetchTDAEInformation } from './useFetchTDAEInformation'

export const useCheckTDAEServiceSubscription = () => {
  const {
    data: { corporate },
    isLoading: isCorporateInformationLoading,
    isError: isCorporateInformationError,
    errorRefetch: corporateInformationErrorRefetch,
  } = useAuthenticatedUserCorporateQuery()

  const {
    tdaeInformation,
    tdaeInformationError,
    isFetchTDAEInformationLoading,
    isFetchTDAEInformationError,
    errorRefetch: fetchTDAEInformationErrorRefetch,
  } = useFetchTDAEInformation()

  const canAccessTdaeUrssafService = corporate?.features?.isUrssafTDAEEligible

  const isTDAEInformationNotFoundError =
    tdaeInformationError?.response?.data?.error?.message ===
    'corporate_tdae_information_not_found'

  const isTDAEServiceNeverSubscribed =
    !tdaeInformation?.status || tdaeInformation?.status === TDAEStatus.Undefined

  const isLoading =
    isCorporateInformationLoading || isFetchTDAEInformationLoading

  const isCheckError =
    isCorporateInformationError ||
    (isFetchTDAEInformationError && !isTDAEInformationNotFoundError)

  const errorRefetch = useCallback(() => {
    if (isCorporateInformationError) {
      corporateInformationErrorRefetch()
    } else if (isFetchTDAEInformationError) {
      fetchTDAEInformationErrorRefetch()
    }
  }, [
    corporateInformationErrorRefetch,
    fetchTDAEInformationErrorRefetch,
    isCorporateInformationError,
    isFetchTDAEInformationError,
  ])

  return {
    canAccessTdaeUrssafService,
    isLoading,
    isCheckError,
    isCheckSuccess: !isLoading && !isCheckError,
    isTDAEServiceNeverSubscribed,
    tdaeInformation,
    errorRefetch,
  }
}
