import { EnumOfferServiceId } from 'modules/corporate/types/offers'

export enum EnumServiceSlugs {
  FACTORING = 'affacturage',
  CREDIT = 'credit',
  PAYMENT_TERMINAL = 'terminal-paiement',
  ACCOUNTING = 'expertise-comptable',
  SIMPLIS_INSURANCE = 'mutuelle-simplis',
  SIMPLIS_RC_PRO = 'rc-pro-simplis',
  SIMPLIS_DECADE_GUARANTEE = 'garantie-decennale-simplis',
  SIMPLIS_PREVOYANCE = 'prevoyance-simplis',
  SIMPLIS_MULTIRISK = 'assurance-multirisque-simplis',
  TDAE_URSSAF = 'tierce-declaration-urssaf',
  OTHER_SERVICES_LCL = 'autres-services',
  FINANCING = 'financements-importants',
  WEBSITE_CREATION = 'creation-web-site',
  DIGITAL_VISIBILITY = 'visibilite-digitale',
}

export const MAP_SERVICE_ID_TO_SLUG: Partial<
  Record<EnumOfferServiceId, EnumServiceSlugs>
> = {
  [EnumOfferServiceId.factoring]: EnumServiceSlugs.FACTORING,
  [EnumOfferServiceId.credit]: EnumServiceSlugs.CREDIT,
  [EnumOfferServiceId.paymentTerminal]: EnumServiceSlugs.PAYMENT_TERMINAL,
  [EnumOfferServiceId.accounting]: EnumServiceSlugs.ACCOUNTING,
  [EnumOfferServiceId.urssaf]: EnumServiceSlugs.TDAE_URSSAF,
  [EnumOfferServiceId.healthcare]: EnumServiceSlugs.SIMPLIS_INSURANCE,
  [EnumOfferServiceId.professionalLiability]: EnumServiceSlugs.SIMPLIS_RC_PRO,
  [EnumOfferServiceId.providentInsurance]: EnumServiceSlugs.SIMPLIS_PREVOYANCE,
  [EnumOfferServiceId.multiriskInsurance]: EnumServiceSlugs.SIMPLIS_MULTIRISK,
  [EnumOfferServiceId.tenYearGuarantee]:
    EnumServiceSlugs.SIMPLIS_DECADE_GUARANTEE,
  [EnumOfferServiceId.otherServicesLCL]: EnumServiceSlugs.OTHER_SERVICES_LCL,
  [EnumOfferServiceId.financing]: EnumServiceSlugs.FINANCING,
  [EnumOfferServiceId.websiteCreation]: EnumServiceSlugs.WEBSITE_CREATION,
  [EnumOfferServiceId.digitalVisibility]: EnumServiceSlugs.DIGITAL_VISIBILITY,
}

export const MAP_SERVICE_SLUG_TO_ID: Record<
  EnumServiceSlugs,
  EnumOfferServiceId
> = {
  [EnumServiceSlugs.FACTORING]: EnumOfferServiceId.factoring,
  [EnumServiceSlugs.CREDIT]: EnumOfferServiceId.credit,
  [EnumServiceSlugs.PAYMENT_TERMINAL]: EnumOfferServiceId.paymentTerminal,
  [EnumServiceSlugs.ACCOUNTING]: EnumOfferServiceId.accounting,
  [EnumServiceSlugs.TDAE_URSSAF]: EnumOfferServiceId.urssaf,
  [EnumServiceSlugs.SIMPLIS_INSURANCE]: EnumOfferServiceId.healthcare,
  [EnumServiceSlugs.SIMPLIS_RC_PRO]: EnumOfferServiceId.professionalLiability,
  [EnumServiceSlugs.SIMPLIS_PREVOYANCE]: EnumOfferServiceId.providentInsurance,
  [EnumServiceSlugs.SIMPLIS_MULTIRISK]: EnumOfferServiceId.multiriskInsurance,
  [EnumServiceSlugs.SIMPLIS_DECADE_GUARANTEE]:
    EnumOfferServiceId.tenYearGuarantee,
  [EnumServiceSlugs.OTHER_SERVICES_LCL]: EnumOfferServiceId.otherServicesLCL,
  [EnumServiceSlugs.FINANCING]: EnumOfferServiceId.financing,
  [EnumServiceSlugs.WEBSITE_CREATION]: EnumOfferServiceId.websiteCreation,
  [EnumServiceSlugs.DIGITAL_VISIBILITY]: EnumOfferServiceId.digitalVisibility,
}
export interface PostCcpmContactLeadPayload {
  firstname: string
  lastname: string
  email: string
  phoneNumber: string
  isMicro: boolean
  accountant: boolean
}
